// @ts-ignore
import grapesjs from 'grapesjs'
import s3 from '@config/aws'
import App from "@config/app"
import './gjs-plugin-s3.css'

export default grapesjs.plugins.add('gjs-plugin-s3', (editor, opts = {}) => {
    let c = opts;
    let config = editor.getConfig();
    let pfx = config.stylePrefix || '';
    let btnEl;

    let defaults = {
        // Custom button element which triggers s3 modal
        btnEl: '',

        // Text for the button in case the custom one is not provided
        btnText: 'Add images',

        imgFormats: ["png", "jpeg", "jpg"],
        bucketName: App.awsBucketName,
        prefix    : '',

        // On complete upload callback
        // blobs - Array of Objects, eg. [{url:'...', filename: 'name.jpeg', ...}]
        // assets - Array of inserted assets
        onComplete: (blobs, assets) => {
        },
    };

    // Load defaults
    for (let name in defaults) {
        if (!(name in c))
            c[name] = defaults[name];
    }

    if (!c.prefix) {
        throw new Error('no prefix found for gjs plugin s3')
    }

    const loader = document.createElement('div')
    loader.id = 'cover-spin';
    document.body.append(loader);

    function listS3Objects(params)
    {
        const s3Params = {
            Bucket: c.bucketName,
            Prefix: c.prefix
        };

        s3.listObjects(s3Params, (err, data) => {
            if (err) {
                console.error(err)
                return
            }

            const signedUrls = data.Contents.map((s3Object) => {
                if (c.imgFormats.some((imgFormat) => s3Object.Key.includes(imgFormat))) {
                    let fileName = s3Object.Key.split('/');
                    fileName = fileName[fileName.length - 1];
                    const s3ObjectUrl = `${App.awsAssetsEndpoint}/${s3Object.Key}`;
                    return {
                        url : s3ObjectUrl,
                        name: fileName
                    }
                } else {
                    return undefined
                }
            }).filter(signedUrl => signedUrl !== undefined);
            addAssets(signedUrls);
        })
    }

    listS3Objects()

    // When the Asset Manager modal is opened
    editor.on('run:open-assets', () => {
        let inputEl;
        const modal = editor.Modal;
        const modalBody = modal.getContentEl();
        const uploader = modalBody.querySelector('.' + pfx + 'am-file-uploader');
        const assetsHeader = modalBody.querySelector('.' + pfx + 'am-assets-header');
        const assetsBody = modalBody.querySelector('.' + pfx + 'am-assets-cont');

        uploader && (uploader.style.display = 'none');
        assetsHeader && (assetsHeader.style.display = 'none');
        assetsBody.style.width = '100%';

        // Instance button if not yet exists
        if (!btnEl) {
            btnEl = c.btnEl;

            if (!btnEl) {
                btnEl = document.createElement('button');
                btnEl.className = pfx + 'btn-prim ' + pfx + 'btn-s3';
                btnEl.innerHTML = c.btnText;
                inputEl = document.createElement("INPUT");
                inputEl.setAttribute("type", "file");
                inputEl.style.opacity = 0;
                inputEl.onchange = (e) => {
                    let file = e.target.files[0]
                    // upload to s3
                    uploadToS3(file)
                }
                document.body.appendChild(inputEl);
            }

            btnEl.onclick = () => {
                // TODO should create a popup to upload new image.
                inputEl.click()
            }
        }

        assetsBody.insertBefore(btnEl, assetsHeader)
    })

    /**
     * Add new assets to the editor
     * @param {Array} files
     */
    const addAssets = (files) => {
        let urls = files.map((file) => {
            file.src = file.url;
            return file;
        })
        urls = urls.filter(url => {
            return !editor.AssetManager.get(url.url)
        })
        return editor.AssetManager.add(urls)
    }

    /**
     * Upload file to s3.
     * @param {fileObject} file
     */
    const uploadToS3 = (file) => {
        showLoader();
        const objectName = c.prefix + file.name;
        const params = {
            Bucket     : c.bucketName,
            Key        : objectName,
            ContentType: file.type,
            Body       : file
        }
        s3.putObject(params, (err, data) => {
            if (err) {
                console.error(err)
            } else {
                listS3Objects();
                hideLoader();
            }
        });
    }

    /**
     * Displays loader.
     */
    function showLoader()
    {
        loader.style.display = 'block'
    }

    /**
     * Hides loader
     */
    function hideLoader()
    {
        loader.style.display = 'none';
    }

});
