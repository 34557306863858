import React from "react"
import Dialog from "@material-ui/core/Dialog"
import {useDispatch, useSelector} from "react-redux"
import {getDialogOptions, isDialogOpen} from "@store/app/selectors"
import * as Actions from '@store/app/actions';

const AppDialog = () => {
    const dispatch = useDispatch()
    const open = useSelector(isDialogOpen)
    const options = useSelector(getDialogOptions)

    return (
        <Dialog
            open={open}
            onClose={ev => dispatch(Actions.closeDialog())}
            {...options}
        />
    )
}

export default AppDialog
