import AWS from "aws-sdk"
import AppConfig from "@config/app"

AWS.config.update({
    region: AppConfig.awsBucketName,
    credentials: new AWS.Credentials({
        accessKeyId: AppConfig.awsAccessKeyId,
        secretAccessKey: AppConfig.awsSecretAccessKey,
    })
})

const s3 = new AWS.S3({
    endpoint: AppConfig.awsEndpoint,
    apiVersion: "2006-03-01",
    params: {
        Bucket: AppConfig.awsBucketName
    }
})

export default s3
