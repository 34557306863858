import * as Actions from './actions';

const initialState = {
    assets: [],
    content: {
        key: null,
        initial: null,
        reset: false,
    }
}

const editor = (state = initialState, action: any) => {
    switch (action.type) {
        case Actions.SET_ASSETS: {
            return {
                ...state,
                assets: action.assets,
            }
        }
        case Actions.CHANGE_EDITOR_CONTENT: {
            return {
                ...state,
                content: {
                    key: action.key,
                    initial: action.content,
                    reset: action.reset,
                }
            }
        }
        default: {
            return state
        }
    }
}

export default editor
