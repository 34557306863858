import AppConfig from "@config/app"

export function getNameFromKey(key: string) {
    return key.split('/').pop()!.split('.')[0]
}

export function getKeyPrefixFromKey(key: string) {
    return key.split('/')[0]
}

export function calculateHtmlUrlFromKey(key: string) {
    const split = key.split('.')
    split.pop()
    return `${AppConfig.awsAssetsEndpoint}/${split.join('.')}.html`
}

export function getMjmlKeyFromName({prefix, name}: {prefix: string, name: string}) {
    return `${prefix}/pages/${name}.mjml`
}

export function getHtmlKeyFromName({prefix, name}: {prefix: string, name: string}) {
    return `${prefix}/pages/${name}.html`
}

export function getImgAssetPrefix(prefix: string): string {
    return `${prefix}/img/`
}
