import {ReactElement} from "react"

export const OPEN_DRAWER = '[APP] OPEN DRAWER'
export const CLOSE_DRAWER = '[APP] CLOSE DRAWER'

export const OPEN_DIALOG = '[APP] OPEN DIALOG'
export const CLOSE_DIALOG = '[APP] CLOSE DIALOG'

export const SHOW_PROGRESS = '[APP] SHOW DIALOG'
export const HIDE_PROGRESS = '[APP] HIDE DIALOG'

export const ENQUEUE_SNACKBAR = '[APP] ENQUEUE SNACKBAR'
export const CLOSE_SNACKBAR = '[APP] CLOSE SNACKBAR'
export const REMOVE_SNACKBAR = '[APP] REMOVE SNACKBAR'

export const openDrawer = () => ({
    type: OPEN_DRAWER,
})

export const closeDrawer = () => ({
    type: CLOSE_DRAWER,
})

interface OpenDialogProps {
    children: ReactElement

    [key: string]: any
}

export const openDialog = (options: OpenDialogProps) => ({
    type: OPEN_DIALOG,
    options,
})

export const closeDialog = () => ({
    type: CLOSE_DIALOG,
})

export const showProgress = () => ({
    type: SHOW_PROGRESS,
})

export const hideProgress = () => ({
    type: HIDE_PROGRESS,
})

export const enqueueSnackbar = (bar: any) => {
    const key = bar.options && bar.options.key

    return {
        type: ENQUEUE_SNACKBAR,
        bar: {
            ...bar,
            key: key || new Date().getTime() + Math.random(),
        },
    }
}

export function enqueueSuccessSnackbar(message: string) {
    return enqueueSnackbar({
        message: message,
        options: {
            variant: 'success',
        }
    })
}

export function enqueueErrorSnackbar(message: string) {
    return enqueueSnackbar({
        message: message,
        options: {
            variant: 'error',
        }
    })
}

export const closeSnackbar = (key: string) => ({
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
})

export const removeSnackbar = (key: string) => ({
    type: REMOVE_SNACKBAR,
    key,
})
