import s3 from "@config/aws"
import {AWSError} from "aws-sdk"
import {
    CopyObjectOutput,
    DeleteObjectOutput,
    GetObjectOutput,
    ListObjectsOutput,
    Object as S3Object,
    PutObjectOutput,
    PutObjectRequest
} from "aws-sdk/clients/s3"
import AppConfig from "@config/app"
import {enqueueErrorSnackbar, enqueueSuccessSnackbar, hideProgress, showProgress} from "@store/app/actions"
import {getKeyPrefixFromUserName} from "@store/common"
import {getHtmlKeyFromName, getKeyPrefixFromKey, getMjmlKeyFromName, getNameFromKey} from "@common"
import _ from 'lodash'
import moment from "moment"
import axios from 'axios'
import {userIsAdmin} from "@store/auth/selectors"

export const CHANGE_EDITOR_CONTENT = '[EDITOR] CHANGE CONTENT'
export const SET_ASSETS = '[EDITOR] SET ASSETS'

async function awsListObjectByPrefix(prefix: string): Promise<ListObjectsOutput> {
    return new Promise((resolve, reject) => s3.listObjects({
        Prefix: prefix, Bucket: AppConfig.awsBucketName,
    }, (err: AWSError, data: ListObjectsOutput) => (err ? reject(err) : resolve(data))))
}

async function awsPutObject(params: PutObjectRequest): Promise<PutObjectOutput> {
    return new Promise((resolve, reject) => s3.putObject(params,
        (err: AWSError, data: PutObjectOutput) => (err ? reject(err) : resolve(data)))
    )
}

async function awsDeleteObjectByKey(key: string): Promise<DeleteObjectOutput> {
    return new Promise((resolve, reject) => s3.deleteObject({
        Key: key, Bucket: AppConfig.awsBucketName,
    }, (err: AWSError, data: DeleteObjectOutput) => (err ? reject(err) : resolve(data))))
}

async function awsGetObjectByKey(key: string): Promise<GetObjectOutput> {
    return await new Promise((resolve, reject) => s3.getObject({
        Key: key, Bucket: AppConfig.awsBucketName,
    }, (err: AWSError, data: GetObjectOutput) => (err ? reject(err) : resolve(data))))
}

async function awsPutMjmlContent({key, mjml}: { key: string, mjml: string }): Promise<PutObjectOutput> {
    return await awsPutObject({
        Key: key,
        Body: mjml,
        Bucket: AppConfig.awsBucketName,
        ContentType: 'application/xhtml+xml',
    })
}

async function awsCopyObjectByKey({sourceKey, destKey}: { sourceKey: string, destKey: string }): Promise<CopyObjectOutput> {
    return new Promise((resolve, reject) => s3.copyObject({
            Bucket: AppConfig.awsBucketName,
            Key: destKey,
            CopySource: `${AppConfig.awsBucketName}/${sourceKey}`,
        }, (err: AWSError, data: PutObjectOutput) => (err ? reject(err) : resolve(data)))
    )
}

async function awsPutHtmlContent({key, html}: { key: string, html: string }): Promise<PutObjectOutput> {
    return await awsPutObject({
        Key: key,
        Body: html,
        Bucket: AppConfig.awsBucketName,
        ContentType: 'text/html',
    })
}

const localStorageOpenKey = 'ezpage-open-key'

function changeEditorContent({key, content, reset}: { content: string | null, key: string, reset: boolean }) {
    return (dispatch: any) => {
        localStorage.setItem(localStorageOpenKey, key)
        dispatch({
            type: CHANGE_EDITOR_CONTENT, content, key, reset
        })
    }
}

export function inspectLocalStorageForCurrentKey() {
    return (dispatch: any) => {
        const key = localStorage.getItem(localStorageOpenKey)
        if (key !== null && key.length >= 0) {
            dispatch(changeEditorContent({key: key, content: null, reset: false}))
        }
    }
}

async function loadUtf8ContentByKey(key: string): Promise<string> {
    const data = await awsGetObjectByKey(key)
    return new TextDecoder('utf-8').decode(data.Body as Uint8Array)
}

export function retrieveAssets() {
    return async (dispatch: any, getState: any) => {
        const prefix = userIsAdmin(getState()) ? '' : getKeyPrefixFromUserName(getState())

        const data = await awsListObjectByPrefix(prefix)
        const mjmlRegex = new RegExp(`${prefix}/.+mjml`)
        const assets = (data.Contents || []).filter(it => mjmlRegex.test(it.Key || ''))

        dispatch({
            type: SET_ASSETS,
            assets: _.orderBy(assets, (a: S3Object) => moment(a.LastModified).valueOf(), 'desc')
        })
    }
}

function duplicateFileWithoutRetrieval({key, name}: { key: string, name: string }) {
    return async (dispatch: any, getState: any) => {
        const newPrefix = getKeyPrefixFromUserName(getState())
        const oldPrefix = getKeyPrefixFromKey(key)

        const oldMjmlKey = key
        const newName = name

        const oldName = getNameFromKey(oldMjmlKey)
        const oldHtmlKey = getHtmlKeyFromName({prefix: oldPrefix, name: oldName})

        const newMjmlKey = getMjmlKeyFromName({prefix: newPrefix, name: newName})
        const newHtmlKey = getHtmlKeyFromName({prefix: newPrefix, name: newName})

        await Promise.all([
            awsCopyObjectByKey({sourceKey: oldMjmlKey, destKey: newMjmlKey}),
            awsCopyObjectByKey({sourceKey: oldHtmlKey, destKey: newHtmlKey})
        ])
    }
}

export function deleteFileWithoutRetrieval(key: string) {
    return async (dispatch: any, getState: any) => {
        const prefix = getKeyPrefixFromKey(key)
        const name = getNameFromKey(key)

        await Promise.all([
            awsDeleteObjectByKey(getMjmlKeyFromName({prefix, name})),
            awsDeleteObjectByKey(getHtmlKeyFromName({prefix, name})),
        ])
    }
}

export function refreshAssets() {
    return async (dispatch: any, getState: any) => {
        dispatch(showProgress())

        await retrieveAssets()(dispatch, getState)
        dispatch(enqueueSuccessSnackbar('刷新成功'))

        dispatch(hideProgress())
    }
}

export function loadFile(key: string) {
    return async (dispatch: any) => {
        dispatch(showProgress())

        const content = await loadUtf8ContentByKey(key)
        dispatch(changeEditorContent({key, content, reset: true}))

        dispatch(hideProgress())
    }
}

export function saveFile({key, mjml, html}: { key: string, mjml: string, html: string }) {
    return async (dispatch: any, getState: any) => {
        const prefix = getKeyPrefixFromKey(key)
        const name = getNameFromKey(key)

        dispatch(showProgress())

        await Promise.all([
            awsPutMjmlContent({key: getMjmlKeyFromName({prefix, name}), mjml}),
            awsPutHtmlContent({key: getHtmlKeyFromName({prefix, name}), html}),
        ])

        await retrieveAssets()(dispatch, getState)
        dispatch(enqueueSuccessSnackbar(`保存 ${name} 成功`))
        dispatch(hideProgress())
    }
}

export function newFile(name: string) {
    return async (dispatch: any, getState: any) => {
        const prefix = getKeyPrefixFromUserName(getState())
        dispatch(changeEditorContent({key: getMjmlKeyFromName({prefix, name}), content: null, reset: true}))
    }
}

export function deleteFile(key: string) {
    return async (dispatch: any, getState: any) => {
        dispatch(showProgress())

        await deleteFileWithoutRetrieval(key)(dispatch, getState)

        await retrieveAssets()(dispatch, getState)
        dispatch(enqueueSuccessSnackbar(`删除 ${getNameFromKey(key)} 成功`))
        dispatch(hideProgress())
    }
}

export function duplicateFile({key, name}: { key: string, name: string }) {
    return async (dispatch: any, getState: any) => {
        dispatch(showProgress())

        await duplicateFileWithoutRetrieval({key, name})(dispatch, getState)

        await retrieveAssets()(dispatch, getState)
        dispatch(enqueueSuccessSnackbar(`复制到 ${name} 成功`))
        dispatch(hideProgress())
    }
}

export function renameFile({key, name}: { key: string, name: string }) {
    return async (dispatch: any, getState: any) => {
        dispatch(showProgress())

        await duplicateFileWithoutRetrieval({key, name})(dispatch, getState)
        await deleteFileWithoutRetrieval(key)(dispatch, getState)

        await retrieveAssets()(dispatch, getState)
        dispatch(enqueueSuccessSnackbar(`重命名为 ${name} 成功`))
        dispatch(hideProgress())
    }
}

export function sendEmail({email, title, content}: { email: string, title: string, content: string }) {
    return async (dispatch: any, getState: any) => {
        dispatch(showProgress())

        await axios({
            method: 'POST',
            url: 'https://work.indoing.com/api/aliyun/send-simple-email',
            data: {
                email,
                title,
                content,
            },
        }).then(async () => {
            dispatch(enqueueSuccessSnackbar(`发送邮件到 ${email} 成功`))
        }).catch(e => {
            console.error(e)
            dispatch(enqueueErrorSnackbar(`发送邮件到 ${email} 失败`))
        })

        dispatch(hideProgress())
    }
}
