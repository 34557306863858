import {combineReducers} from 'redux'
import app from "@store/app/reducers"
import auth from "@store/auth/reducers"
import editor from "@store/editor/reducers"

const createReducer = (asyncReducers) => {
    return combineReducers({
        app,
        editor,
        auth,
        ...asyncReducers
    })
}

export default createReducer
