const App = Object.freeze({
    adalTenant: 'indoing.onmicrosoft.com',
    adalClientId: 'eb927535-8ba1-476a-a274-869a9a60d28f',

    awsBucketName: "indoing-net-ezpage-asset",
    awsBucketRegion: "oss-cn-shanghai",
    awsAccessKeyId: "LTAI4FwNBeVceDBEqNnZ6vGh",
    awsSecretAccessKey: "t2XI3TTTSG9juWJK865hVC0t3noXV3",
    awsEndpoint: "https://oss-cn-shanghai.aliyuncs.com",
    awsAssetsEndpoint: "https://ezasset.indoing.net",

    defaultEditorComponents: '<mjml><mj-body></mj-body></mjml>',

    drawerWidth: 350,

    admin: [
        'felix@indoing.com',
        'john@indoing.com',
    ],
})

export default App
