import React from "react"
import LinearProgress from "@material-ui/core/LinearProgress"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {useSelector} from "react-redux"
import {isProgressing} from "@store/app/selectors"

const useStyles = makeStyles(theme => ({
    root: {
        // height: theme.spacing(2),
    },
}))

const AppProgress = () => {
    const classes = useStyles()
    const enable = useSelector(isProgressing)

    return (<>
        {enable && (<LinearProgress classes={{root: classes.root}}/>)}
    </>)
}

export default AppProgress
