import {runWithAdal} from 'react-adal'
import {adalContext} from '@config/adal'
import React from 'react'
import ReactDOM from 'react-dom'
import './styles/tailwind.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {setAuthUser} from "@store/auth/actions"
import store from "@store"
import '@config/aws'

import 'moment/locale/zh-cn'
import moment from "moment"
moment.locale('zh-cn')

const DO_NOT_LOGIN = false

runWithAdal(adalContext, () => {
    adalContext.getUser((errorDesc, user) => {
        if (errorDesc) {
            alert(errorDesc)
        } else {
            store.dispatch(setAuthUser(user))
        }
    })

    ReactDOM.render(<App/>, document.getElementById('root'))
}, DO_NOT_LOGIN)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
