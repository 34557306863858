import React from "react"
import Drawer from "@material-ui/core/Drawer"
import {useDispatch, useSelector} from "react-redux"
import {isDrawerOpen} from "@store/app/selectors"
import {closeDialog, closeDrawer, openDialog} from "@store/app/actions"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListItemText from "@material-ui/core/ListItemText"
import moment from "moment"
import AppConfig from "@config/app"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {deleteFile, duplicateFile, loadFile, newFile, refreshAssets, renameFile} from "@store/editor/actions"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import AddIcon from '@material-ui/icons/Add'
import InputIcon from '@material-ui/icons/Input'
import RefreshIcon from '@material-ui/icons/Refresh'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from "@material-ui/core/TextField"
import {getAssets, getEditorCurrentKey} from "@store/editor/selectors"
import Typography from "@material-ui/core/Typography"
import {getKeyPrefixFromKey, getNameFromKey} from "@common"
import * as _ from 'lodash'
import {ExpansionPanel} from "@material-ui/core"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import {getKeyPrefixFromUserName} from "@store/common"

const useStyles = makeStyles(theme => ({
    drawer: {
        width: AppConfig.drawerWidth,
    }
}))

function LoadAssetDialog({objectKey}: { objectKey: string }) {
    const dispatch = useDispatch()

    return (<>
            <DialogTitle>载入确认</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    确定载入 <b>{getNameFromKey(objectKey)}</b> 吗？这将清空当前画布。
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(closeDialog())} color="secondary" autoFocus>
                    取消
                </Button>
                <Button onClick={() => {
                    dispatch(loadFile(objectKey))
                    dispatch(closeDialog())
                    dispatch(closeDrawer())
                }} color="primary">
                    确定
                </Button>
            </DialogActions>
        </>
    )
}

function InputNameDialog({title, action}: { title: string, action: (name: string) => void }) {
    const dispatch = useDispatch()

    const assets = useSelector(getAssets)
    const [assetName, setAssetName] = React.useState('')

    const nameError = React.useMemo(() => (
        /[\s./\\]/.test(assetName) || assets.some(a => getNameFromKey(a.Key || '') === assetName)
    ), [assetName, assets])

    return (<>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className='flex flex-col'>
            <TextField
                label='名称'
                value={assetName} onChange={e => setAssetName(e.target.value)}
                variant='outlined'
                error={nameError}
                helperText={nameError && '名称已被占用或包含特殊字符'}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => dispatch(closeDialog())} color="secondary">
                取消
            </Button>
            <Button
                onClick={() => action(assetName)}
                color="primary" autoFocus
                disabled={nameError || assetName.length === 0}
            >
                确定
            </Button>
        </DialogActions>
    </>)

}

function NewAssetDialog() {
    const dispatch = useDispatch()

    return (
        <InputNameDialog
            title='新建'
            action={(name) => {
                dispatch(newFile(name))
                dispatch(closeDialog())
                dispatch(closeDrawer())
            }}
        />
    )
}

function DuplicateAssetDialog({objectKey}: { objectKey: string }) {
    const dispatch = useDispatch()

    return (
        <InputNameDialog
            title='复制'
            action={(name) => {
                dispatch(duplicateFile({key: objectKey, name}))
                dispatch(closeDialog())
            }}
        />
    )
}

function RenameAssetDialog({objectKey}: { objectKey: string }) {
    const dispatch = useDispatch()

    return (
        <InputNameDialog
            title='重命名'
            action={(name) => {
                dispatch(renameFile({key: objectKey, name}))
                dispatch(closeDialog())
            }}
        />
    )
}

function DeleteAssetDialog({objectKey}: { objectKey: string }) {
    const dispatch = useDispatch()

    return (<>
            <DialogTitle>删除确认</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    确定删除 <b>{getNameFromKey(objectKey)}</b> 吗？这个操作无法恢复。
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(closeDialog())} color="secondary" autoFocus>
                    取消
                </Button>
                <Button onClick={() => {
                    dispatch(deleteFile(objectKey))
                    dispatch(closeDialog())
                }} color="primary">
                    确定
                </Button>
            </DialogActions>
        </>
    )
}

function preventDefaultAndStopPropagation(event: React.MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
}

function AppDrawer() {
    const classes = useStyles({})

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(refreshAssets())
    }, [dispatch])

    const keyPrefix = useSelector(getKeyPrefixFromUserName)
    const drawerOpen = useSelector(isDrawerOpen)
    const contentKey = useSelector(getEditorCurrentKey)
    const assets = useSelector(getAssets)

    const groupped = React.useMemo(() => (
        Object.entries(_.groupBy(assets, asset => getKeyPrefixFromKey(asset.Key as string)))
    ), [assets])

    return (
        <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => dispatch(closeDrawer())}
        >
            <List component="nav" disablePadding className={classes.drawer}>
                <IconButton
                    title='新建'
                    onClick={() => dispatch(openDialog({
                        children: <NewAssetDialog/>
                    }))}
                >
                    <AddIcon/>
                </IconButton>
                <IconButton
                    title='刷新'
                    onClick={() => dispatch(refreshAssets())}
                >
                    <RefreshIcon/>
                </IconButton>
                {contentKey && (<Typography variant='body2'>当前文件: {getNameFromKey(contentKey)}</Typography>)}
                {!contentKey && (<Typography variant='body2' color='secondary'>未打开文件</Typography>)}

                <Divider/>

                {groupped.map(([groupName, groupList]) => (
                    <ExpansionPanel key={groupName}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>
                                {groupName}
                                {keyPrefix === groupName ? '*' : ''}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <List component="nav" disablePadding className='w-full'>
                                {groupList.map(asset => (
                                    <ListItem key={asset.Key} button onClick={() => {
                                        dispatch(openDialog({
                                            children: <LoadAssetDialog objectKey={asset.Key as string}/>
                                        }))
                                    }}>
                                        <ListItemText
                                            primary={getNameFromKey(asset.Key as string)}
                                            secondary={moment(asset.LastModified).fromNow()}
                                        />
                                        <IconButton
                                            title='重命名' size='small'
                                            onClick={(e) => {
                                                preventDefaultAndStopPropagation(e)
                                                dispatch(openDialog({
                                                    children: <RenameAssetDialog objectKey={asset.Key as string}/>
                                                }))
                                            }}
                                        >
                                            <InputIcon/>
                                        </IconButton>
                                        <IconButton
                                            title='复制' size='small'
                                            onClick={(e) => {
                                                preventDefaultAndStopPropagation(e)
                                                dispatch(openDialog({
                                                    children: <DuplicateAssetDialog objectKey={asset.Key as string}/>
                                                }))
                                            }}
                                        >
                                            <FileCopyOutlinedIcon/>
                                        </IconButton>
                                        <IconButton
                                            title='删除' size='small'
                                            onClick={(e) => {
                                                preventDefaultAndStopPropagation(e)
                                                dispatch(openDialog({
                                                    children: <DeleteAssetDialog objectKey={asset.Key as string}/>
                                                }))
                                            }}
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    </ListItem>
                                ))}
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
            </List>
        </Drawer>
    )
}

export default AppDrawer
