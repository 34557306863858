import * as Actions from './actions';

const initialState = {
    user: null,
}

const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case Actions.SET_AUTH_USER: {
            return {
                ...state,
                user: action.user,
            };
        }
        default: {
            return state;
        }
    }
}

export default auth
