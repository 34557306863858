import {getUser} from "@store/auth/selectors"

function stringHash(s: string) {
    let hash = 0
    if (s.length === 0) return hash;
    for (let i = 0; i < s.length; i++) {
        const chr   = s.charCodeAt(i)
        hash  = ((hash << 5) - hash) + chr
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash)
}

export function getKeyPrefixFromUserName(state: any): string {
    const user = getUser(state)
    const userName = user.userName

    if (!userName) {
        throw new Error('userName not found')
    }

    return stringHash(userName).toString()
}

console.log('getKeyPrefixFromUserName', stringHash('john@indoing.com'))
