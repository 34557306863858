import React from "react"
// @ts-ignore
import Div100vh from 'react-div-100vh'

import AppEditor from "@component/AppEditor"
import AppDialog from "@component/AppDialog"
import AppProgress from "@component/AppProgress"
import AppSnackBar from "@component/AppSnackBar"

function Layout() {
    return (
        <>
            <Div100vh className='flex flex-col flex-1'>
                <AppEditor/>
                <AppProgress/>
            </Div100vh>
            <AppSnackBar/>
            <AppDialog/>
        </>
    )
}

export default Layout
