import React from 'react'

import 'grapesjs/dist/css/grapes.min.css'
// @ts-ignore
import grapesJS from 'grapesjs'
// @ts-ignore
// import grapesJSMJML from 'grapesjs-mjml'
// @ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard'

import AppConfig from "@config/app"
import {useDispatch, useSelector} from "react-redux"
import {closeDialog, enqueueSnackbar, openDialog, openDrawer} from "@store/app/actions"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {getEditorCurrentKey, getEditorState} from "@store/editor/selectors"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogContent from "@material-ui/core/DialogContent"
import CloseIcon from "@material-ui/icons/Close"
import {calculateHtmlUrlFromKey, getImgAssetPrefix, getNameFromKey} from "@common"
import AppDrawer from "@component/AppDrawer"
import {/*inspectLocalStorageForCurrentKey, */ saveFile, sendEmail} from "@store/editor/actions"
// @ts-ignore
import Frame from 'react-frame-component'
import {Slider} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import '@plugin/grapesjs-plugin-s3.js'

// @ts-ignore
import 'grapesjs-plugin-ckeditor'
import {getKeyPrefixFromUserName} from "@store/common"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import {getUser} from "@store/auth/selectors"

// declare var grapesjs: any
declare var CKEDITOR: any

const devicesPanelClassName = 'gjs-pn-devices-c'

const useStyles = makeStyles(theme => ({
    editor: {
        [`& .${devicesPanelClassName}`]: {
            left: AppConfig.drawerWidth,
        }
    }
}))

const Commands = {
    Switch: 'switch-file',
    Preview: 'preview-html-content',
    Save: 'save-content',
    Link: 'external-link',
    Email: 'send-email',
}

function getHtmlFromEditor(editor: any) {
    return editor!.Commands!.run('mjml-get-code')!.html
}

function getMjmlFromEditor(editor: any) {
    return editor!.getHtml()
}

function loadMjmlIntoEditor(editor: any, mjml: string) {
    editor!.setComponents(mjml)
}

function addOpenDrawerPanel(editor: any) {
    const panelManager = editor.Panels
    panelManager.addPanel({
        id: 'drawerPanel',
        visible: true,
        buttons: [{
            id: 'drawer-button',
            className: 'fa fa-list',
            command: Commands.Switch,
            attributes: {title: 'Open Drawer'},
        }],
    })
}

function addPreviewContentButton(editor: any) {
    const panelManager = editor.Panels
    panelManager.addButton('options', {
        id: 'preview-html-button',
        className: 'fa fa-eye',
        command: Commands.Preview,
        attributes: {title: 'Preview'},
    })
}

function addSaveContentButton(editor: any) {
    const panelManager = editor.Panels
    panelManager.addButton('options', {
        id: 'save-content-button',
        className: 'fa fa-save',
        command: Commands.Save,
        attributes: {title: 'Save'},
    })
}

function addExternalLinkButton(editor: any) {
    const panelManager = editor.Panels
    panelManager.addButton('options', {
        id: 'external-link-button',
        className: 'fa fa-external-link',
        command: Commands.Link,
        attributes: {title: 'Link'},
    })
}

function addSendEmailButton(editor: any) {
    const panelManager = editor.Panels
    panelManager.addButton('options', {
        id: 'send-email-button',
        className: 'fa fa-envelope',
        command: Commands.Email,
        attributes: {title: 'Send Email'},
    })
}

function createGrapesJsEditor(container: any, prefix: string) {
    const editor = grapesJS.init({
        fromElement: false,
        components: AppConfig.defaultEditorComponents,
        container: container,
        // mediaCondition: 'min-width', // default is `max-width`
        storageManager: false,
        plugins: ['grapesjs-mjml', 'gjs-plugin-ckeditor', 'gjs-plugin-s3'],
        height: '100%',
        width: '100%',
        pluginsOpts: {
            'grapesjs-mjml': {
                columnsPadding: null,
                defaultTemplate: AppConfig.defaultEditorComponents,
            },
            'gjs-plugin-ckeditor': {
                position: 'center',
                options: {
                    startupFocus: true,
                    extraAllowedContent: '*(*);*{*}', // Allows any class and any inline style
                    allowedContent: true, // Disable auto-formatting, class removing, etc.
                    enterMode: CKEDITOR.ENTER_BR,
                    extraPlugins: 'sharedspace,justify,colorbutton,panelbutton,font',
                    toolbar: [
                        {name: 'styles', items: ['Font', 'FontSize']},
                        ['Bold', 'Italic', 'Underline', 'Strike'],
                        {name: 'paragraph', items: ['NumberedList', 'BulletedList']},
                        {name: 'links', items: ['Link', 'Unlink']},
                        {name: 'colors', items: ['TextColor', 'BGColor']},
                    ],
                    font_names:
                        'DefaultFont/-apple-system,BlinkMacSystemFont,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,Microsoft JhengHei,SimHei,sans-serif;' +
                        'Arial/Arial, Helvetica, sans-serif;' +
                        'Comic Sans MS/Comic Sans MS, cursive;' +
                        'Courier New/Courier New, Courier, monospace;' +
                        'Georgia/Georgia, serif;' +
                        'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;' +
                        'Tahoma/Tahoma, Geneva, sans-serif;' +
                        'Times New Roman/Times New Roman, Times, serif;' +
                        'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;' +
                        'Verdana/Verdana, Geneva, sans-serif;' +
                        'Dekers/dekers_true'
                }
            },
            'gjs-plugin-s3': {
                prefix: getImgAssetPrefix(prefix)
            }
        }
    })

    editor.on('load', () => {
        const styleManager = editor.StyleManager
        const fontProperty = styleManager.getProperty('typography', 'font-family')
        const list = fontProperty.get('list')
        list[0] = ({
            name: 'DefaultFont',
            value: '-apple-system,BlinkMacSystemFont,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,Microsoft JhengHei,SimHei,sans-serif',
        })
        fontProperty.set('list', list)
        styleManager.render()
    })

    editor.setDevice('Tablet')

    return editor
}

function EditorNoContentDialog() {
    const dispatch = useDispatch()

    return (<>
        <DialogTitle>无文件</DialogTitle>
        <DialogContent>
            <DialogContentText>
                尚未选择文件，请新建或打开文件后重试
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                color="primary" autoFocus
                onClick={() => {
                    dispatch(openDrawer())
                    dispatch(closeDialog())
                }}
            >
                确定
            </Button>
        </DialogActions>
    </>)
}

function ExternalLinkDialog() {
    const dispatch = useDispatch()
    const currentKey = useSelector(getEditorCurrentKey)

    if (!currentKey) {
        return (<EditorNoContentDialog/>)
    }

    const currentUrl = calculateHtmlUrlFromKey(currentKey)

    return (<>
        <DialogTitle>链接</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {currentUrl}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" onClick={() => dispatch(closeDialog())}>取消</Button>

            <CopyToClipboard
                text={currentUrl}
                onCopy={() => {
                    dispatch(closeDialog())
                    dispatch(enqueueSnackbar({
                        message: `链接已复制`,
                        options: {
                            variant: 'info',
                        }
                    }))
                }}
            >
                <Button color="primary">复制</Button>
            </CopyToClipboard>

            <Button color="primary" autoFocus onClick={() => {
                window.open(currentUrl, '_blank')
                dispatch(closeDialog())
            }}>
                打开
            </Button>
        </DialogActions>
    </>)
}

function SaveContentDialog({editor}: { editor: any }) {
    const dispatch = useDispatch()
    const currentKey = useSelector(getEditorCurrentKey)
    const [title, setTitle] = React.useState('')

    if (!currentKey) {
        return (<EditorNoContentDialog/>)
    }

    return (<>
        <DialogTitle>保存</DialogTitle>
        <DialogContent>
            <Box m={1}>
                <TextField
                    label='网页标题' variant='outlined' fullWidth={true} placeholder='网页标题'
                    value={title} onChange={e => setTitle(e.target.value)}
                />
            </Box>
            <DialogContentText>
                确定要保存到 <strong>{getNameFromKey(currentKey)}</strong> 吗？
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" onClick={() => dispatch(closeDialog())}>取消</Button>
            <Button color="primary" autoFocus onClick={() => {
                const rawHtml = getHtmlFromEditor(editor)
                const titleStartTag = '<title>'
                const position = rawHtml.indexOf(titleStartTag)
                const before = rawHtml.substring(0, position + titleStartTag.length)
                const after = rawHtml.substring(position + titleStartTag.length, rawHtml.length)
                dispatch(saveFile({
                    key: currentKey,
                    mjml: getMjmlFromEditor(editor),
                    html: `${before}${title}${after}`,
                }))
                dispatch(closeDialog())
            }}>
                保存
            </Button>
        </DialogActions>
    </>)
}

function SendEmailDialog({editor}: { editor: any }) {
    const dispatch = useDispatch()
    const currentKey = useSelector(getEditorCurrentKey)
    const user = useSelector(getUser)
    const [email, setEmail] = React.useState(user.userName)
    const [title, setTitle] = React.useState('测试邮件标题')

    if (!currentKey) {
        return (<EditorNoContentDialog/>)
    }

    return (<>
        <DialogTitle>发送邮件</DialogTitle>
        <DialogContent className='flex flex-col'>
            <Box m={1}>
                <TextField
                    label='邮件地址' variant='outlined' fullWidth={true}
                    value={email} onChange={e => setEmail(e.target.value)}
                />
            </Box>
            <Box m={1}>
                <TextField
                    label='邮件标题' variant='outlined' fullWidth={true}
                    value={title} onChange={e => setTitle(e.target.value)}
                />
            </Box>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" onClick={() => dispatch(closeDialog())}>取消</Button>
            <Button color="primary" autoFocus onClick={() => {
                dispatch(sendEmail({
                    email: email,
                    title: title,
                    content: getHtmlFromEditor(editor),
                }))
                dispatch(closeDialog())
            }}>
                发送
            </Button>
        </DialogActions>
    </>)
}

const usePreviewContentDialogStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(1, 3, 1, 3),
    },
    content: {
        padding: theme.spacing(5, 3, 2, 3),
    },
}))

function PreviewContentDialog({editor}: { editor: any }) {
    const dispatch = useDispatch()
    const classes = usePreviewContentDialogStyles()

    const html = React.useMemo(() => getHtmlFromEditor(editor), [editor])

    const [iframeWidth, setIframeWidth] = React.useState(100)
    const iframeStyle = React.useMemo(() => ({width: `${iframeWidth}%`, height: '100%'}), [iframeWidth])

    return (<>
        <DialogTitle disableTypography className='flex flex-row justify-between' classes={{root: classes.title}}>
            <Typography variant='h6'>预览</Typography>
            <IconButton size='small' onClick={() => dispatch(closeDialog())}>
                <CloseIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent className='flex flex-col flex-1 justify-center' classes={{root: classes.content}}>
            <Slider
                min={1}
                max={100}
                value={iframeWidth}
                onChange={(e, v) => {
                    if (typeof v === 'number' && v >= 5) {
                        setIframeWidth(v)
                    }
                }}
                step={1}
                valueLabelDisplay="on"
            />
            <Frame style={iframeStyle} initialContent={html}/>
        </DialogContent>
    </>)
}

function useMjmlEditor(container: any) {
    const dispatch = useDispatch()
    const prefix = useSelector(getKeyPrefixFromUserName)
    const [mjmlEditor, setMjmlEditor] = React.useState(null)

    React.useEffect(() => {
        if (!container || !prefix) {
            return
        }

        const editor = createGrapesJsEditor(container, prefix)

        editor.Commands.add(Commands.Switch, (editor: any, sender: any) => dispatch(openDrawer()))

        editor.Commands.add(Commands.Preview, (editor: any, sender: any) => dispatch(openDialog({
            fullScreen: true,
            children: <PreviewContentDialog editor={editor}/>
        })))

        editor.Commands.add(Commands.Save, (editor: any, sender: any) => dispatch(openDialog({
            children: <SaveContentDialog editor={editor}/>
        })))

        editor.Commands.add(Commands.Link, (editor: any, sender: any) => dispatch(openDialog({
            children: <ExternalLinkDialog/>
        })))

        editor.Commands.add(Commands.Email, (editor: any, sender: any) => dispatch(openDialog({
            children: <SendEmailDialog editor={editor}/>
        })))

        addOpenDrawerPanel(editor)
        addPreviewContentButton(editor)
        addSaveContentButton(editor)
        addExternalLinkButton(editor)
        addSendEmailButton(editor)

        setMjmlEditor(editor)

        return (() => {
            setMjmlEditor(null)
            editor.destroy()
        })
    }, [container, prefix, dispatch])

    return mjmlEditor
}

function AppEditor() {
    const classes = useStyles()

    const [ref, setRef] = React.useState()
    const editor = useMjmlEditor(ref)

    const dispatch = useDispatch()
    const editorState = useSelector(getEditorState)

    // React.useEffect(() => {
    //     dispatch(inspectLocalStorageForCurrentKey())
    // }, [dispatch])

    React.useEffect(() => {
        if (!editor || !editorState.key || !editorState.reset) {
            return
        }

        if (editorState.initial) {
            loadMjmlIntoEditor(editor, editorState.initial)
        } else {
            loadMjmlIntoEditor(editor, AppConfig.defaultEditorComponents)
        }

        dispatch(enqueueSnackbar({
            message: `载入 ${getNameFromKey(editorState.key)} 成功`,
            options: {
                variant: 'success',
            }
        }))
    }, [dispatch, editor, editorState])

    return (<>
        <div className={classes.editor} ref={setRef}/>
        <AppDrawer/>
    </>)
}

export default AppEditor
