import React from 'react';
import {Provider as ReduxProvider} from 'react-redux'
import store from "@store"
import {CssBaseline} from "@material-ui/core"
import Layout from "@component/Layout"

const App: React.FC = () => {
    return (
        <ReduxProvider store={store}>
            <CssBaseline/>
            <Layout/>
        </ReduxProvider>
    )
}

export default App
