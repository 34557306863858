
export const isDrawerOpen = (state: any): boolean => state.app.drawer

export const isDialogOpen = (state: any): boolean => state.app.dialog.open
export const getDialogOptions = (state: any): any => state.app.dialog.options

// export const isProgressing = (state: any): boolean => true
export const isProgressing = (state: any): boolean => state.app.progress

export const getSnackbars = (state: any): any[] => state.app.snackbars
