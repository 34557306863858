import {AdalConfig, AuthenticationContext, UserInfo} from 'react-adal'
import AppConfig from '@config/app'

const adal = {
    tenant: AppConfig.adalTenant,
    clientId: AppConfig.adalClientId,
    cacheLocation: 'sessionStorage',
}

export const adalContext = new AuthenticationContext(adal as AdalConfig)

export function getAdalUserDetail(): UserInfo {
    return adalContext.getCachedUser()
}

export function adalLogout() {
    adalContext.logOut()
}
