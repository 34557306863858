import React, {useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as Actions from '@store/app/actions'
import {getSnackbars} from "@store/app/selectors"
import {SnackbarProvider, useSnackbar} from 'notistack'
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

const InnerSnackBar = () => {
    const notistack = useSnackbar()

    const dispatch = useDispatch()

    const snackbars = useSelector(getSnackbars)
    const displayed = useRef<string[]>([])

    const storeDisplayed = React.useCallback((key: string) => {
        displayed.current = [...displayed.current, key];
    }, [])

    const removeDisplayed = React.useCallback((key: string) => {
        displayed.current = displayed.current.filter(it => it !== key)
    }, [])

    React.useEffect(() => {
        snackbars.forEach(({key, message, options = {}, dismissed = false}) => {
            if (dismissed) {
                notistack.closeSnackbar(key)
                return
            }

            // Do nothing if snackbar is already displayed
            if (displayed.current.includes(key)) return

            // Display snackbar using notistack
            notistack.enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event: any, reason: any, key: string) => {
                    if (typeof options.onClose === 'function') {
                        options.onClose(event, reason, key)
                    }
                },
                onExited: (event: any, key: string) => {
                    dispatch(Actions.removeSnackbar(key))
                    removeDisplayed(key)
                }
            })

            // Keep track of snackbars that we've displayed
            storeDisplayed(key)
        })
    }, [snackbars, storeDisplayed, removeDisplayed, notistack, dispatch])

    return null
}

const AppSnackBar = () => {
    const dispatch = useDispatch()

    const action = React.useCallback((key: string) => (
        <IconButton
            title='关闭'
            onClick={() => dispatch(Actions.closeSnackbar(key))}
        >
            <CloseIcon/>
        </IconButton>
    ), [dispatch])

    return (
        <SnackbarProvider action={action}>
            <InnerSnackBar/>
        </SnackbarProvider>
    )
}

export default AppSnackBar
