import * as Actions from './actions';

const defaultDialogOptions = {
    children: 'Hi!',
}

const initialState = {
    drawer: true,
    dialog: {
        open: false,
        options: defaultDialogOptions,
    },
    progress: false,
    snackbars: [],
}

const app = (state = initialState, action: any) => {
    switch (action.type) {
        case Actions.ENQUEUE_SNACKBAR:
            return {
                ...state,
                snackbars: [
                    ...state.snackbars,
                    {
                        key: action.key,
                        ...action.bar,
                    },
                ],
            };
        case Actions.CLOSE_SNACKBAR:
            return {
                ...state,
                snackbars: state.snackbars.map((bar: any) => (
                    (action.dismissAll || bar.key === action.key)
                        ? {...bar, dismissed: true}
                        : {...bar}
                )),
            }
        case Actions.REMOVE_SNACKBAR:
            return {
                ...state,
                snackbars: state.snackbars.filter(
                    (bar: any) => bar.key !== action.key,
                ),
            }

        case Actions.OPEN_DRAWER: {
            return {
                ...state,
                drawer: true,
            }
        }
        case Actions.CLOSE_DRAWER: {
            return {
                ...state,
                drawer: false,
            }
        }
        case Actions.OPEN_DIALOG: {
            return {
                ...state,
                dialog: {
                    open: true,
                    options: action.options,
                },
            }
        }
        case Actions.CLOSE_DIALOG: {
            return {
                ...state,
                dialog: {
                    open: false,
                    options: defaultDialogOptions,
                },
            }
        }
        case Actions.SHOW_PROGRESS: {
            return {
                ...state,
                progress: true,
            }
        }
        case Actions.HIDE_PROGRESS: {
            return {
                ...state,
                progress: false,
            }
        }
        default: {
            return state
        }
    }
}

export default app
